import { put, select, takeLatest } from 'redux-saga/effects'
import {
  ADD_USER_REQUEST,
  GET_USERS_REQUEST,
  REMOVE_USER_REQUEST,
  EDIT_USER_REQUEST
} from '../actions/action-types'
import {
  getUsersSuccessAction,
  getUsersErrorAction,
  removeUserSuccessAction,
  removeUserErrorAction,
  addUserSuccessAction,
  addUserErrorAction,
  editUserSuccessAction,
  editUserErrorAction
} from '../actions/user-list-actions'
import { getUsers, updateUser, addUser } from '../api/backend-api'
import { configSelector } from '../selectors/config-selector'
import { enqueueSuccessSnackbarAction } from '../actions/snackbar-actions'

export function* watchGetUsers() {
  yield takeLatest(GET_USERS_REQUEST, getUsersSaga)
}

function* getUsersSaga({ params, userType }) {
  try {
    const config = yield select(configSelector)
    const users = yield getUsers(config, userType, params)
    yield put(getUsersSuccessAction({ users }))
    if (params.offset > 0 && users.length === 0) {
      yield put(enqueueSuccessSnackbarAction({ message: `No more ${userType}s to load` }))
    } else if (users.length === 0) {
      yield put(enqueueSuccessSnackbarAction({ message: `No ${userType}s found` }))
    }
  } catch (error) {
    yield put(getUsersErrorAction({ error }))
  }
}

export function* watchAddUser() {
  yield takeLatest(ADD_USER_REQUEST, addUserSaga)
}

function* addUserSaga({ email, isOperator, isModerator, onCloseDialog }) {
  try {
    const config = yield select(configSelector)
    let user
    if (!isOperator && !isModerator) {
      throw new Error('User must be either operator or moderator')
    }
    if (isOperator) {
      user = yield addUser(config, 'operator', email)
    }
    if (isModerator) {
      user = yield addUser(config, 'moderator', email)
    }
    yield put(addUserSuccessAction({ user }))
    onCloseDialog()
    yield put(enqueueSuccessSnackbarAction({ message: `User ${email} created` }))
  } catch (error) {
    yield put(addUserErrorAction({ error }))
  }
}

export function* watchRemoveUser() {
  yield takeLatest(REMOVE_USER_REQUEST, removeUserSaga)
}

function getUserRoleKey(userType) {
  switch (userType) {
    case 'moderator':
      return 'isModerator'
    case 'operator':
      return 'isOperator'
    default:
      throw new Error(`Unknown user type '${userType}'`)
  }
}

function* removeUserSaga({ users, userType, onCloseDialog }) {
  try {
    const config = yield select(configSelector)
    for (const { externalId, externalIdSource } of users) {
      yield updateUser(config, externalIdSource, externalId, {
        [getUserRoleKey(userType)]: false
      })
    }
    onCloseDialog()
    yield put(removeUserSuccessAction({ ids: users.map(u => u.id) }))
  } catch (error) {
    yield put(removeUserErrorAction({ error }))
  }
}

export function* watchEditUser() {
  yield takeLatest(EDIT_USER_REQUEST, editUserSaga)
}

function* editUserSaga({ user, isOperator, isModerator, onCloseDialog }) {
  try {
    const config = yield select(configSelector)
    const { externalIdSource, externalId } = user
    const bodyParams = {}
    if (user.isOperator !== isOperator) {
      bodyParams.isOperator = isOperator
    }
    if (user.isModerator !== isModerator) {
      bodyParams.isModerator = isModerator
    }
    yield updateUser(config, externalIdSource, externalId, bodyParams)
    onCloseDialog()
    yield put(editUserSuccessAction({ id: user.id, isOperator, isModerator }))
  } catch (error) {
    yield put(editUserErrorAction({ error }))
  }
}
